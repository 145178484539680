import React from "react";
import { Row, Col, Menu, Dropdown, Icon} from "antd";
import { Link, StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";


const menu = (
  <Menu>
    <Menu.Item>
      <Link id="gir" to='/guide-investissement-rationnel'>Le guide de l'investissement rationnel</Link>
    </Menu.Item>
    <Menu.Item>
      <Link id="gcc" to='/guide-connaissance-client'>Le guide de la connaissance client</Link>
    </Menu.Item>
    <Menu.Item>
      <Link id="sws" to='/sites-web'>Sites web statiques</Link>
    </Menu.Item>
    <Menu.Item>
      <Link id="dshb" to='/dashboard'>Dashboards</Link>
    </Menu.Item>
  </Menu>
);

export default () => (
  <StaticQuery
  query={graphql`
    query {
      logo: file(relativePath: {eq: "img/logo/V2-Logo-Statiste-B.png"}) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 200){
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `}
  render={data => (
    <Row style={{
      backgroundColor: 'white',
      position: 'fixed',
      top: '0px',
      width: '100%',
      zIndex: '1000',
    }}>
    <Col span={24}>
    <Row type="flex" align="middle" justify="space-between" style={{height:'50px', borderBottom:'solid black 1px'}}>
      <Col offset={1} span={3}>
        <Link to='/'><Img fluid={data.logo.childImageSharp.fluid} /></Link>
      </Col>
      </Row>
      <Row type="flex" align="middle" justify="space-between" style={{height:'30px', margin:'0.3em 0em 0.8em 0em'}}>
      <Col offset={15} span={3} align="center">
        <Dropdown overlay={menu}>
          <h2>Offres <Icon type="down" /></h2>
        </Dropdown>
      </Col>
      <Col span={3} align="center">
        <Link to='/about'><h2>A propos</h2></Link>
      </Col>
      <Col span={3} align="center">
        <Link to='/contact'><h2>Contact</h2></Link>
      </Col>
      </Row>
      </Col>
    </Row>
  )}
  />
)
