import React, { useState } from "react";
import { Link } from "gatsby";
import layoutStyles from "./layout.module.css";
import NavBar from './navBar';
import Navbartop from './navbartop';
import { Layout, Row, Col, Icon} from "antd";
import { useSiteMetadata } from "../hooks/use-site-metadata";
const { Footer, Content } = Layout;


const CustomFooter = (
  <Row type="flex" align="top" justify="space-around">
    <Col span={7} offset={0} align="center">
      <Link style={{color: 'gray'}} to="/about"><h2>A propos</h2></Link>
    </Col>
    <Col span={7} align="center">
      <a href="https://fr.linkedin.com/in/baptisterios"><Icon id="linkedin" type="linkedin" theme="filled"/></a>
    </Col>
    <Col span={7} align="center">
      <Link style={{color: 'gray'}} to="/contact"><h2>Contact</h2></Link>
    </Col>
  </Row>
);

export default ({ color, children }) => {
  const { abstract1 } = useSiteMetadata();
  const [ openDrawer, setOpenDrawer ] = useState(false);
  return (
    <Layout
      className={layoutStyles.layout}
    >
      <Row>
        <Col xs={{span:24}} md={{span:0}}>
          <NavBar color={color} openDrawer={openDrawer} clickDrawerIcon={() => setOpenDrawer(!openDrawer)}/>
        </Col>
        <Col xs={{span:0}} md={{span:24}}>
          <Navbartop />
        </Col>
      </Row>
      <Row style={{marginTop:'9vh'}}>
      <Col span={20} offset={2}>
        <Content>
        {children}
        </Content>
      </Col>
      </Row>
      <Row>
      <Col md={{span:0}} xs={{span:24}}>
      <Footer style={{
        backgroundColor: 'white',
        position: 'fixed',
        height: '4em',
        bottom: '0px',
        width: '100%',
        zIndex: '1000'
      }}>
        {CustomFooter}
      </Footer>
      <Row>
      <Col style={{height:'6em'}}>
      </Col>
      </Row>
      </Col>
      <Col md={{span:24}} xs={{span:0}}>
      <Footer style={{
        backgroundColor: 'white'}}
      >
        {CustomFooter}
      </Footer>
      </Col>
      </Row>
    </Layout>
  )
}
