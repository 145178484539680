import { useStaticQuery, graphql } from "gatsby"
export const useSiteMetadata = () => {
  const data  = useStaticQuery(
    graphql`
      query {
        json {
          title
        },
        abstractBackground: file(relativePath: {eq:"img/whiteBricks2.jpg"}) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920){
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        server: file(relativePath: {eq:"img/servers.jpg"}) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920){
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        },
        abstract2: file(relativePath: {eq:"img/abstract2.jpg"}) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920){
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  return data
}
