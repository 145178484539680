import React from "react";
import { Icon, Button, Drawer, Row, Col } from "antd";
import { Link, StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: {eq: "img/logo/V2-Logo-Statiste-B.png"}) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 200){
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <div>
      <Row
        type="flex"
        justify="start"
        align="top"
        style={{
          backgroundColor: 'white',
          position: 'fixed',
          top: '0px',
          width: '100%',
          zIndex: '1000',
        }}
      >
      <Col span={12} offset={0} style={{height:'7vh'}}><Link to='/'><Img style={{height:'60%',marginTop:'0.3em'}} fluid={data.logo.childImageSharp.fluid} /></Link></Col>
      <Col push={8} span={1}><Button onClick={props.clickDrawerIcon} style={{border: 'none', color: 'black'}}>
        <Icon type="menu" theme="outlined"/>
      </Button></Col>
      </Row>
      <Drawer
        title={(<Col span={12}><Img fluid={data.logo.childImageSharp.fluid} /></Col>)}
        placement="right"
        closable={true}
        onClose={props.clickDrawerIcon}
        visible={props.openDrawer}
        bodyStyle={{'background-color': 'white', height: '100%'}}
      >
        <Row style={{marginBottom: '1em', marginTop: '1em'}} type="flex">
          <Col span={2}><Link to="/"><Icon type="home" theme="filled" style={{'color': props.color, 'paddingRight': '0.5em'}} /></Link></Col>
          <Col span={22}><Link to="/"><h2>Accueil</h2></Link></Col>
        </Row>
        <Row style={{marginBottom: '1em', marginTop: '1em'}} type="flex">
          <Col span={2}><Link to="/guide-investissement-rationnel">
          <Icon type="book" theme="filled" style={{'color': props.color, 'paddingRight': '0.5em'}} />
          </Link></Col>
          <Col span={22}><Link to="/guide-investissement-rationnel"><h2>Guide de l'investissement rationnel</h2></Link></Col>
        </Row>
        <Row style={{marginBottom: '1em', marginTop: '1em'}} type="flex">
          <Col span={2}><Link to="/guide-connaissance-client">
          <Icon type="book" theme="outlined" style={{'color': props.color, 'paddingRight': '0.5em'}} />
          </Link></Col>
          <Col span={22}><Link to="/guide-connaissance-client"><h2>Guide de la connaissance client</h2></Link></Col>
        </Row>
        <Row style={{marginBottom: '1em', marginTop: '1em'}}>
          <Col span={2}><Link to="/sites-web"><Icon type="laptop" theme="outlined" style={{'color': props.color, 'paddingRight': '0.5em'}} /></Link></Col>
          <Col span={22}><Link to="/sites-web"><h2>Sites web</h2></Link></Col>
        </Row>
        <Row style={{marginBottom: '1em', marginTop: '1em'}} type="flex">
          <Col span={2}><Link to="/dashboard"><Icon type="monitor" theme="outlined" style={{'color': props.color, 'paddingRight': '0.5em'}} /></Link></Col>
          <Col span={22}><Link to="/dashboard"><h2>Offre dashboard</h2></Link></Col>
        </Row>
        <Row style={{marginBottom: '1em', marginTop: '1em'}}>
          <Col span={2}><Link to="/contact"><Icon type="mail" theme="filled" style={{'color': props.color, 'paddingRight': '0.5em'}} /></Link></Col>
          <Col span={22}><Link to="/contact"><h2>Contact</h2></Link></Col>
        </Row>
        <Row style={{marginBottom: '1em', marginTop: '1em'}}>
          <Col span={2}><Link to="/about"><Icon type="right" theme="outlined" style={{'color': props.color, 'paddingRight': '0.5em'}} /></Link></Col>
          <Col span={22}><Link to="/about"><h2>A propos</h2></Link></Col>
        </Row>
      </Drawer>
      </div>
      )}
  />)
