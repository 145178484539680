import React from 'react';
import Media from 'react-media';
import {WindowDimensionsCtx} from './windowDimensionsProvider';
import Layout from './layout';
import 'antd/dist/antd.css'

export default ({ color, children }) => (
  <Media query={{ maxWidth: 768 }}>
  {matches => {
    const device = matches ? 'mobile' : 'desktop';
    return (
      <WindowDimensionsCtx.Provider value={device}><Layout color={color}>{children}</Layout></WindowDimensionsCtx.Provider>
    )}
  }
</Media>
)
